import {
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Alert as ChakraAlert,
  Flex,
} from '@chakra-ui/react';

type Props = {
  title?: string;
  message?: string;
  onClick: () => void;
  buttonText: string;
};

const Alert = ({ title, message, onClick, buttonText }: Props) => {
  return (
    <ChakraAlert
      status='error'
      flexDirection='row'
      justifyContent='space-between'
      backgroundColor='red.50'
      border='1px'
      borderColor='red.500'
      boxSizing='border-box'
    >
      <Flex flexDirection='row' alignItems='center'>
        <AlertIcon />
        <Flex flexDirection='column' justifyContent='center'>
          {title && <AlertTitle color='gray.800'>{title}</AlertTitle>}
          {message && <AlertDescription color='gray.800'>{message}</AlertDescription>}
        </Flex>
      </Flex>

      <Button colorScheme='primary' variant='outline' onClick={onClick}>
        {buttonText}
      </Button>
    </ChakraAlert>
  );
};

export default Alert;
