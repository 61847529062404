import { useToast } from '@/utils/atoms/toast';
import { Center, Spinner } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ErrorBoundary } from '@sentry/remix';
import { PropsWithChildren, ReactNode, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from './Alert';

type Props = {
  fallback?: ReactNode;
};

const chunkFailedErrorId = 'check-failed-error-toast-id';
const chunkFailedMessages: RegExp[] = [
  /TypeError: 'text\/html' is not a valid JavaScript MIME type./,
  /TypeError: Failed to fetch dynamically imported module:/,
];

export const SuspenseWithSpinner = ({ children, fallback }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { toast } = useToast();

  const onError = (e: unknown, componentStack: string | undefined, eventId: string) => {
    const error = e as Error;
    if (error.message && chunkFailedMessages.some((message) => message.test(error.message))) {
      toast({
        // chunkFailedErrorId is used to prevent duplicate toasts
        id: chunkFailedErrorId,
        duration: null,
        status: 'error',
        render: () => {
          return (
            <Alert
              title={t('warning.latest-version-release')}
              onClick={() => window.location.reload()}
              buttonText={t('actions.reload')}
            />
          );
        },
      });
      // エラーは出さずにwarningだけ出す
      datadogLogs.logger.warn(
        'Warning occurred in SuspenseWithSpinner',
        {
          componentStack,
          eventId,
        },
        error
      );
      return;
    }

    datadogLogs.logger.error(
      'Error occurred in SuspenseWithSpinner',
      {
        componentStack,
        eventId,
      },
      error
    );
  };

  return (
    <ErrorBoundary fallback={() => <>{t('error.error')}</>} onError={onError}>
      <Suspense
        fallback={
          fallback ? (
            fallback
          ) : (
            <Center w='full' h='full'>
              <Spinner color='primary.500' />
            </Center>
          )
        }
      >
        {children}
      </Suspense>
    </ErrorBoundary>
  );
};
